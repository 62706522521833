import { computed, Injectable, signal } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { AuthService } from './auth.service';
import { AlertService } from './alert.service';
import { CareOption } from '../pages/get-care/model/care-option.interface';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProfilesService {
  private readonly _profiles = signal<any[] | undefined>(undefined);
  private readonly _forceFetch = signal<boolean>(false);
  forceFetch = computed(this._forceFetch);

  constructor(
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
  ) {}

  updateProfiles(profiles: any): void {
    this._profiles.update(() => profiles);
  }

  updateForceFetch(forceFetch: boolean): void {
    if (forceFetch) {
      this.clear();
    }
    this._forceFetch.update(() => forceFetch);
  }

  clear(): void {
    this._profiles.set(undefined);
  }

  async getProfiles(): Promise<CareOption[]> {
    try {
      if (this._profiles()) {
        return this._profiles() || [];
      }
      const token = await this.authService.getToken();
      const res = await CapacitorHttp.get({
        url: `${environment.ollaApiUrl}/api/supertokens/account/get-assoc-profiles`,
        responseType: 'json',
        headers: { Authorization: `Bearer ${token}`, 'X-TENANT-ID': environment.orgSlug },
      });
      if (res.status === 401) {
        await this.authService.signOut();
        return [];
      }

      if (res.status < 200 || res.status >= 300) throw new Error();
      this.updateProfiles(res.data);
      return res.data;
    } catch (error) {
      await this.alertService.showError();
      return [];
    }
  }
}
